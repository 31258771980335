(function() {
    "use strict";

    var timeoutHandle,
    autoCompleteInput,
    autocomplete,
    autoCompleteTrigger;

    /*var lang = $('body').data('lang');
    lang = lang=="en"?"en":"fr";*/


    $('[name="q"]').on('keyup', function () {
        var q = $(this).val();

        autoCompleteInput = $(this).closest('#helpBarForm').find('.form-helpbar__search input'),
        autocomplete = $(this).closest('#helpBarForm').find('.autocomplete'),
        autoCompleteTrigger = $(this).closest('#helpBarForm').find('.input-group-btn button.btn'); 

        clearTimeout(timeoutHandle);
        timeoutHandle = setTimeout(function () {
            $.ajax({
                //url: '/' + lang + '/api/search?l=' + lang + '&q=' + q,
                url: '/api/search?q=' + q,
                dataType: 'json'
            }).done(function (data) {
                var html = "";
                if (data.data != undefined) {
                    for (var i in data.data) {
                        var entry = data.data[i];
                        if (entry.title != undefined) {
                            $(autoCompleteTrigger).find('span.fa').addClass('fa-close');

                            $(autocomplete).css('display', 'block');
                            if ($(window).outerWidth() >= 768) {
                                $(autoCompleteInput).css('border-bottom-left-radius', '0');
                            }

                            var subTitle = "";
                            html += "<li class='autocomplete__item'>";
                            html += "<a href='" + entry.url + "'>"
                            html += "<span>" + entry.title + "</span>";
                            html += "</a>";
                            html += "</li>";
                        }
                    }
                }
                if (html.length) {
                    html += "<li class='autocomplete__item autocomplete__item--results'>";
                    html += "<a href='" + $('[name="q"]').closest('form').attr('action') + "?q=" + encodeURIComponent(q) + "'>" + 'Voir tous les résultats';
                    html += "</a>";
                    html += "</li>";
                }
                $("#searchbox").html(html);
                $('.nav__search .gr-form__field').css({
                    "border-bottom-left-radius": "0",
                    "border-bottom-right-radius": "0"
                });
            });
        }, 200);
    });

    // $('[name="q"]').on('keyup', function() {
    //     var q = $(this).val();
    //     clearTimeout(timeoutHandle);
    //     timeoutHandle = setTimeout(function(){
    //         $.ajax({
    //             //url: '/' + lang + '/api/search?l=' + lang + '&q=' + q,
    //             url: '/api/search?q=' + q,
    //             dataType: 'json'
    //         }).done(function(data){
    //             var html = "";
    //             if(data.data != undefined) {
    //                 for(var i in data.data) {
    //                     var entry = data.data[i];
    //                     if(entry.title != undefined) {
    //                         $(autoCompleteTrigger).addClass('fa-close');

    //                         $(autocomplete).css('display', 'block');
    //                         if( $(window).outerWidth() >= 768) {
    //                             $(autoCompleteInput).css('border-bottom-left-radius', '0');
    //                         }
                            
    //                         var subTitle = "";
    //                         html += "<li class='autocomplete__item'>";
    //                         html += "<a href='" + entry.url + "'>"
    //                         html += "<span>" + entry.title + "</span>";
    //                         html += "</a>";
    //                         html += "</li>";
    //                     }
    //                 }
    //             }
    //             if(html.length) {
    //                 html += "<li class='autocomplete__item autocomplete__item--results'>";
    //                 html += "<a href='" + $('[name="q"]').closest('form').attr('action') + "?q=" + encodeURIComponent(q) + "'>" + $('[name="q"]').attr("data-seeall-title");
    //                 html += "</a>";
    //                 html += "</li>";
    //             }
    //             $("#searchbox").html(html);
    //             $('.nav__search .gr-form__field').css({
    //                 "border-bottom-left-radius" : "0",
    //                 "border-bottom-right-radius" : "0"
    //             });
    //         });
    //     }, 200);
    // });

    $('#helpBarForm').find('.input-group-btn button.btn').on('click', function(e) {
        if( $(this).find('span.fa').hasClass('fa-close') ) {
            e.preventDefault();
            $(autocomplete).css('display', 'none');
            $(this).find('span.fa').removeClass('fa-close');
        }
    })


    var $trigger_search = $('[trigger-search]'),
        $trigger_search_target = $('[trigger-search-target]');


    if ( $trigger_search.length > 0 && $trigger_search_target.length > 0 ) {

        $trigger_search.click(function(e) {
            e.preventDefault();


            $trigger_search_target.toggleClass('is-active');
            $trigger_search_target.focus();

        });
    }

}());
